import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface PopupData {
  title: string;
  message: string;
  type: string;
  actionText: string;
  showPopup: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppPopupService {
  private _popupData = new BehaviorSubject<PopupData>({
    title: '',
    message: '',
    type: 'default',
    actionText: '',
    showPopup: false,
  });
  actionEvent = new EventEmitter<void>();
  private queue: PopupData[] = [];
  private isPopupShowing = false;

  get popupData$(): Observable<PopupData> {
    return this._popupData.asObservable();
  }

  reset() {
    this._popupData.next({
      title: '',
      message: '',
      type: 'default',
      actionText: '',
      showPopup: false,
    });
    this.queue = [];
    this.isPopupShowing = false;
  }

  private showPopup(data: Partial<PopupData>, duration: number): void {
    const completeData: PopupData = {
      title: '',
      message: '',
      type: 'default',
      actionText: '',
      showPopup: true,
      ...data,
    };
    this.queue.push(completeData);
    this.processQueue(duration);
  }

  private processQueue(duration: number): void {
    if (this.isPopupShowing || this.queue.length === 0) return;

    const data = this.queue.shift();
    if (!data) return;

    this.isPopupShowing = true;
    this._popupData.next(data);

    setTimeout(() => {
      this._popupData.next({ ...data, showPopup: false });
      this.isPopupShowing = false;
      if (this.queue.length > 0) {
        this.processQueue(duration);
      }
    }, duration);
  }

  success(title: string, message: string, duration: number = 3000): void {
    this.showPopup(
      { title, message, type: 'success', actionText: '' },
      duration
    );
  }

  error(title: string, message: string, duration: number = 3000): void {
    this.showPopup({ title, message, type: 'error', actionText: '' }, duration);
  }

  warning(title: string, message: string, duration: number = 3000): void {
    this.showPopup(
      { title, message, type: 'warning', actionText: '' },
      duration
    );
  }

  info(title: string, message: string, duration: number = 3000): void {
    this.showPopup({ title, message, type: 'info', actionText: '' }, duration);
  }

  show(title: string, message: string, duration: number = 3000): void {
    this.showPopup(
      { title, message, type: 'default', actionText: '' },
      duration
    );
  }

  action(title: string, message: string, actionText: string): void {
    this.showPopup({ title, message, type: 'action', actionText }, 0);
  }
}