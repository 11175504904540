const appInformation = {
    production: false,
    name: 'dev',
    version: '1.0.0',
    region: 'us-gov-west-1',
    url: 'localhost:4200',
    endpoint: 'https://dev-be.cristlgov.com',
    sandboxEnabled: false,
  };
  
  const redirects = {
    uam: 'http://localhost:4200/auth/login',
    warfigther: 'https://warfighter-dev.cristlgov.com/',
    safire: 'https://safire-dev.cristlgov.com'
  }


  
  const amplifyInformation = {
    Auth: {
      region: 'us-gov-west-1',
      userPoolId: 'us-gov-west-1_ZqVSmahc8',
      userPoolWebClientId: '3nj8hs855bisorig8aanbtn1r7',
      Cognito: {
        userPoolClientId: '3nj8hs855bisorig8aanbtn1r7',
        userPoolId: 'us-gov-west-1_ZqVSmahc8',
      },
  
      cookieStorage: {
        domain: '.cristlgov.com',
        path: '/',
        expires: 365,
        sameSite: 'strict',
      },
    },
  };

  const cookieInfo = {
    cookieStorage: {
        domain: '.cristlgov.com',
        path: '/',
        expires: 365,
        sameSite: 'strict',
    }
  }
  
  export const environment = {
    ...appInformation,
    redirects,
    cookieInfo,
    amplify: amplifyInformation,
  };
  