import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './shared/services/auth.service';
import { AppPopupService } from './shared/services/app-popup.service';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { Amplify } from 'aws-amplify';
import { environment } from '../environments/environment';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthGuard } from './shared/services/auth-guard.service';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: environment.amplify.Auth.Cognito.userPoolClientId,
      userPoolId: environment.amplify.Auth.Cognito.userPoolId,
      loginWith: {
        username: true,
        email: true, // Optional
        phone: false, // Optional
      },
    }
  }
});

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatIconModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: "rgba(0,0,0,0.1)",
      backdropBorderRadius: "4px",
      primaryColour: "#ffffff",
      secondaryColour: "#ffffff",
      tertiaryColour: "#ffffff",
    }),
    BrowserAnimationsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthService,
    AuthGuard,
    AppPopupService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
