import { HttpClient } from '@angular/common/http';
import { Injectable, resolveForwardRef } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import {
  signIn,
  signUp,
  confirmResetPassword,
  confirmSignIn,
  resetPassword,
  resendSignUpCode,
  type ResendSignUpCodeInput,
  type ConfirmSignInInput,
  fetchAuthSession,
  confirmSignUp,
  fetchUserAttributes,
  getCurrentUser,
  signOut,
  type ConfirmSignUpInput,
  type AuthSession
} from 'aws-amplify/auth';
import { CookieService } from 'ngx-cookie-service';

import { BehaviorSubject, Observable, from, of, pipe, first, interval } from 'rxjs';
import { tap, map, catchError, delay, debounceTime, takeLast, switchMap, debounce, distinctUntilChanged, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;
  private baseUrl = environment.endpoint

  constructor(
    private router: Router,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private cookieService: CookieService
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  private existingUsernames = ['Batman', 'Superman', 'Joker', 'Luthor'];

  callEmailVerify(email: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/auth/validateEmail`, { email })
      .pipe(
        map(
          (response) => {
            if (response.response > 0) {
              return { uniqueEmail: true }
            }
            else {
              return null
            }
          }
        )
      ).pipe(first())
  }

  createEmailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        take(1),
        switchMap(r => {
          return this.callEmailVerify(control.value).pipe(
            map(response => {
              return response
            })
          )
        }),
        first()
      )
    };
  }

  public confirmResetPasswordPromise(username: string, newPassword: string, code: string, pwdHash: string) {
    return new Promise((resolve, reject) => {
      confirmResetPassword({
        username: username,
        newPassword: newPassword,
        confirmationCode: code,
        options: {
          clientMetadata: {
            "Hash": pwdHash
          }
        }
      }).then(passwordReset => {
        resolve(passwordReset)
      }).catch(err => {
        // console.log("bd", err)
        reject(err)
      })
    })
  }

  public signOutPromise() {
    return new Promise((resolve, reject) => {
      signOut().then(signedOut => {
        this.cookieService.delete('uam-id-token', "/", environment.cookieInfo.cookieStorage.domain)
        this.cookieService.delete('uam-refresh-token', "/",  environment.cookieInfo.cookieStorage.domain)
        this.cookieService.delete('uam-device-key', "/",  environment.cookieInfo.cookieStorage.domain)
        // this.cookieService.set('uam-id-token', session?.tokens?.idToken?.toString() as string, { domain: ".cristlgov.com"})

        resolve(signedOut)
      }).catch(err => {
        reject(err)
      })
    })
  }

  public resetPasswordPromise(email: string) {
    return new Promise((resolve, reject) => {
      resetPassword({
        username: email
      }).then(resetPasswordResponse => {
        resolve(resetPasswordResponse)
      }).catch(err => {
        // console.log("reset password error", err)
        reject(err)
      })
    })
  }

  public fetchUserAttributesPromise(): Promise<any> {
    return new Promise((resolve, reject)=>{
      fetchUserAttributes().then(result=>{
        resolve(result)
      }).catch(err=>{
        reject(err)
      })
    })
  }

  public fetchAuthSessionPromise(): Promise<AuthSession> {
    return new Promise((resolve, reject) => {
      fetchAuthSession().then(result => {
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  }

  public checkCurrentUserPromise() {
    return new Promise((resolve, reject) => {
      getCurrentUser().then(result => {
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  }

  public signUpPromise(email: string, password: string, name: string, lastName: string, mfa: string, pwdHash:string, phoneNumber: string) {
    return new Promise((resovle, reject) => {
      signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            'name': name,
            'email': email,
            'family_name': lastName,
            'custom:mfa': mfa,
            'phone_number': "+1"+phoneNumber
          },
          clientMetadata: {
            "Hash": pwdHash
          }
        }
      }).then(signUpResponse => {
        resovle(signUpResponse)
      }).catch(err => {
        // console.log(err)
        reject(err)
      })
    })
  }

  public confirmSignUpPromise(email: string, code: string, hash: string): Promise<any> {
    return new Promise((resolve, reject) => {
      confirmSignUp({
        username: email,
        confirmationCode: code,
        options: {
          autoSignIn: {
            enabled: true
          },
          clientMetadata: {
            "Hash": hash
          }
        }
      }).then(result => {
        resolve(result)
      }).catch(err => {
        // console.log("err on confirm signup", err)
        reject(err)
      })
    })
  }

  public signUp(email: string, password: string): Observable<any> {
    return from(signUp({
      username: email,
      password: password
    }));
  }

  public confirmSignUp(email: string, code: string): Observable<any> {
    let confirm: ConfirmSignUpInput = {
      username: email,
      confirmationCode: code
    }
    return from(confirmSignUp(confirm));
  }

  public reSendCodePromise(email: string) {
    return new Promise((resolve, reject) => {
      resendSignUpCode({ username: email }).then(result => {
        resolve(result)
      }).catch(err => {
        // console.log("bad stf", err)
        reject(err)
      })
    })
  }

  public login(email: string, password: string): Observable<any> {
    return from(this.requestLogin(email, password).then().catch(err=>{
      if(err.toString().includes("UserNotFoundException") || err.toString().includes("NotAuthorizedException"))
      {
        this._snackBar.open("Wrong password or username provided", '', {
          duration: 3000
        })
      }
      if(err.toString().includes("New password required"))
      {
        this._snackBar.open("New password required, 90 day limit passed", '', {
          duration: 3000
        })
        this.router.navigate(['/auth/forgot-password'])
      }
    }))
  }

  public requestLogin(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      signIn({
        username: email,
        password: password,
        options: {
          authFlowType: 'CUSTOM_WITH_SRP'
        }
      }).then(output => {
        resolve(output)
      }).catch(err => {
        // console.log("error logign", err)
        reject(err)
      })
    })
  }

  public confirmSignIn(challenge: string): Promise<any> {
    return new Promise((resolve, reject) => {
      confirmSignIn({
        challengeResponse: challenge
      }).then(response => {
        resolve(response)
      }).catch(err => {
        // console.log("err", err)
        reject(err)
      })
    })
  }

  public getUserDetails(): Observable<any> {
    return from(getCurrentUser()).pipe(
      map((user) => user),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  public isAuthenticated(): Observable<boolean> {
    return from(getCurrentUser()).pipe(
      map(() => {
        this.loggedIn.next(true);
        return true;
      }),
      catchError(() => {
        this.loggedIn.next(false);
        // console.log("is not logged in :: ");
        return of(false);
      })
    );
  }

  public signOut(): void {
    from(signOut()).subscribe({
      next: () => {
        this.loggedIn.next(false);
        this.router.navigate(['/auth/login']);
      },
      error: (error) => console.error(error),
    });
  }
}
