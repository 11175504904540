
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from 'src/environments/environment';
//import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authToken = ''
  constructor(/*private authService: AuthService*/) {
    fetchAuthSession().then(authSession => {
      this.authToken = (authSession.tokens?.idToken?.toString()) as any
    })
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const authToken = "";

    const endPoint = environment.endpoint;
    const excludedUrls = [
      `${endPoint}auth/settings`,
      `${endPoint}/auth/validateEmail`,
    ];
    let authReq: any;
    if (excludedUrls.some((x) => req.url.includes(x))) {
      authReq = req.clone();
      return next.handle(authReq);
    } else {
      let fetchPromise = fetchAuthSession()
      const observablePromise = from(fetchPromise);
      return observablePromise.pipe(
        switchMap((token)=>{
            this.authToken = (token.tokens?.idToken?.toString()) as any
            const authReq = req.clone({
              setHeaders: {
                Authorization: `Bearer ${this.authToken}`
              }
            });
            return next.handle(authReq);
        })
      )
    }
  }

}
