import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Promise<boolean> {
    return this.authService.checkCurrentUserPromise().then(user=>{
        return true
    }).catch(err=>{
        this.router.navigate(['/auth']); // Redirect to login if not authenticated
        return false
    })
  }
}